<template>
  <section class="manage_feat">
    <h1 class="manage_title">Manage Subscription</h1>
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-5 mngsub">
          <div class="cel1">
            <div class="leftimg">
              <img
                class="credit-cards mobilez"
                src="~@/assets/images/headset.png"
                alt="credit-cards logo"
              />
            </div>
            <div class="rightcnt">
              <h3>Need help, or want to change your subscription?</h3>
              <p class="desctitle">
                Call us at <strong>1-888-264-1699</strong> or email us at
                <strong>support@resume-perfect.com</strong>
              </p>
              <br />
              <p class="desctitle">Available 5 days a week</p>
              <br />
              <p class="desctitle">8:00AM - 4:00PM (Pacific Time)</p>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-7 mngsub">
          <div class="cel2">
            <div class="leftimg">
              <img
                class="credit-cards mobilez"
                src="~@/assets/images/warning.png"
                alt="credit-cards logo"
              />
            </div>
            <div class="rightcnt">
              <h3>Need a break?</h3>
              <span class="desctitle"
                >We hope we could help you. Once your subscription is cancelled,
                you will not be able to download, print, and email your
                documents</span
              >
              <a
                v-if="activePlanId != ''"
                href="#"
                class="cancelbtn"
                @click.prevent="openModal"
                >Cancel Subscription</a
              >
              <a v-else href="javascript: void(0)" class="cancelbtn"
                >Cancel Subscription</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="manage_pricing">
      <div class="container">
        <div class="title">
          <h1 class="manage_title">Switch to a plan that works for you</h1>
          <p>Stay with us to save your documents. Cancel anytime.</p>
        </div>
      </div>
      <div class="container">
        <div class="row1">
          <div class="flr2 slick-carousel">
            <VueSlickCarousel v-bind="settings" class="flr2 slick-carousel">
              <div class="col-sm-12 col-md-4 mngfeat1">
                <div class="mngfeat">
                  <h3>1 month <strong>FREE</strong></h3>
                  <ul>
                    <li>Get full access FREE for a month</li>
                    <li>($23.85 billed every four weeks)</li>
                  </ul>
                  <a
                    v-if="activePlanId == 'manage_01'"
                    href="javascript: void(0)"
                    class="choosebtn"
                    >Current Subscription</a
                  >
                  <a
                    v-else
                    href="#"
                    class="choosebtn"
                    @click.prevent="changeSubscription('1')"
                    >Continue</a
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-4 mngfeat2">
                <span>Most Popular</span>
                <div class="mngfeat">
                  <h3><strong>$3.00</strong> / month</h3>
                  <ul>
                    <li>Pay just $3.00/month for full access as long</li>
                    <li>as your job search lasts!</li>
                  </ul>
                  <a
                    v-if="activePlanId == 'manage_02'"
                    href="javascript: void(0)"
                    class="choosebtn"
                    >Current Subscription</a
                  >
                  <a
                    v-else
                    href="#"
                    class="choosebtn"
                    @click.prevent="changeSubscription('2')"
                    >Continue</a
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-4 mngfeat3">
                <div class="mngfeat">
                  <h3><strong>$23.85</strong> / year</h3>
                  <ul>
                    <li>Get a full year’s subscription for</li>
                    <li>the price of one month.</li>
                    <li>($23.85 billed yearly)</li>
                  </ul>
                  <a
                    v-if="activePlanId == 'manage_03'"
                    href="javascript: void(0)"
                    class="choosebtn"
                    >Current Subscription</a
                  >
                  <a
                    v-else
                    href="#"
                    class="choosebtn"
                    @click.prevent="changeSubscription('3')"
                    >Continue</a
                  >
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop" v-if="showModal" @close="showModal = false">
      <div class="managemodal">
        <div class="close_container"></div>
        <div class="topmgn">
          <h3>
            <strong v-html="firstName"></strong>, congratulations on completing
            your resume!
          </h3>
          <p>
            Visit your dashboard to use our job search, access your documents
            and utilize the tools you need to get the job faster!
          </p>
          <a href="#" class="mngbtn" @click.prevent="goToDashboard"
            >Visit your dashboard</a
          >
        </div>
        <div class="lowermgn">
          <div class="bordermgn">
            <div class="col-md-6 leftbox">
              <img
                class="mnglogo"
                src="~@/assets/images/mnglogo.png"
                alt="resume logo"
              />
              <span v-html="planTitle">14-day full access</span>
            </div>
            <div class="col-md-6 rghtbox">
              <span>Your credit card will be charged</span><br />
              <span>starting {{ planNextChargeDate }}</span>
            </div>
          </div>
          <div class="btmmgn">
            <div class="row">
              <div class="col-md-4 billstmnt">
                <span>BILLING STATEMENT</span>
              </div>
              <div class="col-md-8 billdetails">
                <span>Cardholder Name: {{ firstName }} {{ lastName }}</span>
                <span>Order Date: {{ planDate }}</span>
                <span>Amount Billed: ${{ planAmount }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 billstmnt">
                <span>ACCOUNT INFORMATION</span>
              </div>
              <div class="col-md-8 billdetails">
                <span
                  >Username: <strong>{{ userEmail }}</strong></span
                ><br />
                <p>
                  <i>
                    You have the option of managing and canceling your
                    subscription in the account settings. You can also do this
                    by calling our customer support team at
                    <strong>844-351-7484</strong>, or emailing us at
                    <strong>customerservice@resume-perfect.com</strong></i
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AppModal
      :isOpenModal="isOpenSubscribeModal"
      width="460px"
      @close="closeModal"
    >
      <UnsubscribeModal @cancel="closeModal" @unsubscribe="unsubscribeUser" />
    </AppModal>
  </section>
</template>

<script>
import $ from "jquery";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AppToast from "@/helpers/toast-methods";
import UnsubscribeModal from "@/components/account/UnsubscribeModal";

export default {
  name: "Manage Subscription",
  mixins: [mixpanelMixin],
  components: { VueSlickCarousel, UnsubscribeModal },

  computed: {
    ...mapGetters({
      manageSubscriptionPlans: "subscribe/getManageSubscriptions",
      user: "user/user",
    }),
  },

  data() {
    return {
      showModal: false,
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        initialSlide: 1,
        arrows: true,
        swipe: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              dots: true,
              swipe: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
            },
          },
        ],
      },
      manage_01: "",
      manage_02: "",
      manage_03: "",
      activePlanId: "",
      firstName: "",
      lastName: "",
      userEmail: "",
      planAmount: "",
      planTitle: "",
      planDate: "",
      planNextChargeDate: "",
      isOpenSubscribeModal: false,
    };
  },

  async created() {
    this.mixpanelTrack("V2-MANAGE");

    await this.$store.dispatch("loader/pending");
    await this.$store.dispatch("subscribe/getManageSubscriptionPlans");
    await this.getUserData();
    await this.$store.dispatch("loader/done");

    this.manage_01 = this.manageSubscriptionPlans.filter(
      (subs) => subs.identity === "manage_01"
    );

    this.manage_02 = this.manageSubscriptionPlans.filter(
      (subs) => subs.identity === "manage_02"
    );

    this.manage_03 = this.manageSubscriptionPlans.filter(
      (subs) => subs.identity === "manage_03"
    );

    this.activePlanId = this.user.payment_subscription[0]
      ? this.user.payment_subscription[0].identity
      : "";
  },

  methods: {
    openModal() {
      this.isOpenSubscribeModal = true;
    },
    closeModal() {
      this.isOpenSubscribeModal = false;
    },
    goToDashboard() {
      this.$router.push("/dashboard");
    },
    async changeSubscription(plan) {
      await this.$store.dispatch("loader/pending");

      let plan_indetity = "";
      let plan_details = "";

      if (plan == "1" && this.manage_01.length > 0) {
        plan_indetity = this.manage_01[0].identity;
        plan_details = this.manage_01;
      } else if (plan == "2" && this.manage_02.length > 0) {
        plan_indetity = this.manage_02[0].identity;
        plan_details = this.manage_02;
      } else if (plan == "3" && this.manage_03.length > 0) {
        plan_indetity = this.manage_03[0].identity;
        plan_details = this.manage_03;
      }

      if (plan_indetity == "") {
        AppToast.toastError("No plan selected.");
        return;
      }

      try {
        const payload = {
          up_to_date: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
          subscription_plan: plan_indetity,
        };

        let paymentResponse = await this.$store.dispatch(
          "subscribe/changeSubscription",
          payload
        );
        await this.getUserData();

        this.popThankYouPage(plan_details, paymentResponse.data);
        this.showModal = true;
      } catch (err) {
        console.error(err);
      }
      await this.$store.dispatch("loader/done");
    },
    async getUserData() {
      try {
        await this.$store.dispatch("user/getUser");
      } catch (e) {
        console.error(e);
      }
    },
    popThankYouPage(subDetails, paymentDetails) {
      let subConfig = subDetails[0] ? subDetails[0].config : "";
      subConfig = JSON.parse(subConfig);

      let trialDays = subConfig[0] ? subConfig[0].trial_days : 0;
      let currentDate = trialDays;

      this.firstName = this.user.first_name;
      this.lastName = this.user.last_name;
      this.userEmail = this.user.email;
      this.planAmount = paymentDetails.payment_amount;
      this.planTitle = subDetails[0].title;
      this.planNextChargeDate = paymentDetails.payment_charge_date;
      this.planDate = paymentDetails.payment_date;
    },
    async unsubscribeUser() {
      await this.$store.dispatch("loader/pending");
      try {
        await this.$store.dispatch("subscribe/unsubscribeUser");
        this.closeModal();
        this.$router.push("/account");
      } catch (err) {
        console.error(err);
      }
      await this.$store.dispatch("loader/done");
    },
  },
};
</script>

<style lang="scss" scoped>
.billstmnt span {
  font-weight: bold;
}
.close_container button.btn-black {
  font-size: 22px;
  font-family: "sans-serif";
  margin-right: 5px;
}
.lowermgn {
  background: #6993ff;
  color: #ffffff;
  padding: 35px;
  border-radius: 0 0 10px 10px;
  @media (max-width: 320px) {
    padding: 15px;
  }
}
.billdetails span {
  display: block;
}
.billdetails {
  margin-bottom: 20px;
}
a.mngbtn {
  background: #6993ff;
  color: #fff;
  font-weight: 700;
  padding: 15px;
  border-radius: 10px;
  min-width: 300px;
  margin: 0 auto;
  cursor: pointer;
  display: inline-block;
  margin: 25px 0 0;
  @media (max-width: 414px) {
    min-width: 150px;
  }
}
.topmgn h3 {
  color: #6993ff;
  font-weight: 500;
  margin: 10px;
}
.bordermgn {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 15px;
  display: inline-flex;
  width: 100%;
  @media (max-width: 414px) {
    display: block;
  }
}
.rghtbox {
  text-align: right;
  @media (max-width: 414px) {
    text-align: left;
  }
}
.leftbox span {
  display: block;
  font-weight: 700;
}
.btmmgn {
  padding: 15px;
  width: 100%;
}
.topmgn {
  background: #fff;
  padding: 35px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  margin-top: 30px;
}
.close_container {
  position: relative;
  width: 100%;
  text-align: right;
  margin-bottom: -20px;
  @media (max-width: 500px) {
    width: 95%;
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 414px) {
    display: inline-block;
    padding: 5px;
  }
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}
.managemodal {
  padding: 0px;
  border-radius: 10px;
  min-height: 300px;
  width: 70%;
  min-width: 320px;
  @media (max-height: 850px) {
    height: 100%;
    margin-top: 140px;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    margin-top: 140px;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  @media (max-width: 414px) {
    width: 100%;
    word-wrap: break-word;
    height: 90vh;
    margin-top: 61px;
    overflow-x: scroll;
    padding-bottom: 0px;
  }
  @media (max-width: 320px) {
    height: 88vh;
    font-size: 12px;
    min-width: 300px;
  }
  @media screen and (max-width: 767px) and (min-width: 501px) {
    width: 90%;
    margin-top: 90px;
  }
  @media screen and (max-width: 991px) and (min-width: 768px) {
    width: 645px;
    margin-top: 90px;
  }
}
section.typical-page-section {
  padding-top: 40px;
  padding-bottom: 100px;
}

.leftimg {
  width: 60px;
  display: inline-block;
  vertical-align: top;
}

.rightcnt {
  width: 80%;
  display: inline-block;
  @media (max-width: 414px) {
    width: 100%;
    padding-top: 10px;
  }
}

.rightcnt p {
  font-size: 14px;
}

.cel2 h3 {
  margin-bottom: 30px;
}

.cancelbtn {
  text-align: center;
}

.cel2 a.cancelbtn:hover {
  background: #f6f6f6;
}

h1.manage_title {
  text-align: center;
  padding: 10px 0 20px;
  font-size: 22px;
}

.manage_pricing {
  color: #6c6c6c;
  padding: 50px 0;
}

.mngsub {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.mngfeat strong {
  font-size: 26px;
  font-weight: bolder;
}

.cel1,
.cel2 {
  min-height: 300px;
  padding: 50px;
  line-height: 1.6;
  text-align: center;
  display: inline-block;
  font-weight: normal;
  text-align: left;
}

footer.footer-section {
  border: none !important;
}

.flr2.slick-carousel {
}

.mngsub h3 {
  font-weight: 700;
  font-size: 20px;
  color: #4874e6;
}

.cel1 h3 {
  color: #fff;
  padding-bottom: 25px;
}

.manage_pricing .title h3 {
  font-weight: bold;
}

section.manage_pricing {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#edf7f9+0,edf7f9+70,ffffff+71,ffffff+100 */
  background: #edf7f9; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #edf7f9 0%,
    #edf7f9 70%,
    #ffffff 71%,
    #ffffff 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #edf7f9 0%,
    #edf7f9 70%,
    #ffffff 71%,
    #ffffff 100%
  );
  background: linear-gradient(
    to bottom,
    #edf7f9 0%,
    #edf7f9 70%,
    #ffffff 71%,
    #ffffff 100%
  );
}

.mngfeat2 h3 {
  margin-top: 10px;
}

.mngfeat2 .mngfeat ul {
  min-height: 150px;
}
.mngfeat1:hover .mngfeat,
.mngfeat3:hover .mngfeat {
  border: 2px solid #4874e6;
}

.mngfeat2:hover span,
.mngfeat2:active span,
.mngfeat2:focus span {
  border-top: 2px solid #4874e6 !important;
  border-left: 2px solid #4874e6 !important;
  border-right: 2px solid #4874e6 !important;
  border-bottom: none;
}

.mngfeat2:hover .mngfeat,
.mngfeat2:active .mngfeat,
.mngfeat2:focus .mngfeat {
  border-top: none;
  border-left: 2px solid #4874e6 !important;
  border-right: 2px solid #4874e6 !important;
  border-bottom: 2px solid #4874e6 !important;
}

.mngfeat:hover a.choosebtn {
  box-shadow: 0 4px 6px -4px #000000;
  background: #4874e6;
}

section.manage_pricing {
  padding: 50px 0 100px;
}

.manage_pricing .title {
  text-align: center;
  padding: 10px 0 50px 0;
}

section.manage_feat {
  padding-top: 50px;
}

.cel2 a.cancelbtn {
  background: #fff;
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 30px;
  color: #4874e6;
  border: 2px solid #4874e6;
}

.cel1 img,
.cel2 img {
  width: 50px;
}

.mngfeat {
  background: #fff;
  padding: 25px 20px;
  box-shadow: 0 14px 25px -18px black;
  text-align: center;
  height: 320px;
}

a.choosebtn {
  background: #d9d9d9;
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  text-decoration: none;
}

.mngfeat2 a.choosebtn {
  background: #4874e6;
  padding-top: 10px;
}

.mngfeat1 .mngfeat,
.mngfeat3 .mngfeat {
  height: 320px !important;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.mngfeat2 {
  border-radius: 10px 10px 0 0;
  display: contents !important;
}

.mngfeat2 .mngfeat {
  height: 310px;
  border-radius: 0 0 10px 10px;
}

.mngfeat ul {
  list-style: none;
  padding: 20px 0;
  height: 150px;
}

.mngfeat1,
.mngfeat3 {
  margin-top: 46px;
  display: inline !important;
}

.mngfeat1 h3,
.mngfeat3 h3 {
  margin-top: 20px;
}

.mngfeat2 {
  /*margin-top: 13px;*/
}

.mngfeat2 span {
  width: 100%;
  background: #ffffff;
  display: block;
  padding: 15px;
  color: #4874e6;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
}

.mngfeat h3 {
  font-weight: 400;
  font-size: 20px;
}

.mngsub {
  padding: 25px;
}

.cel1 {
  box-shadow: 0 0 10px 0px #a0a0a0;
  border-radius: 15px;
  padding: 25px;
  background: #4874e6;
  color: #fff;
}

.cel2 {
  box-shadow: 0 0 10px 0px #a0a0a0;
  border-radius: 15px;
  padding: 25px;
}

.mngsub a {
  color: #000;
  text-decoration: none;
}

.manage_pricing i#slickPrev,
.manage_pricing i#slickNext {
  cursor: pointer;
  font-size: 25px;
  position: absolute;
  top: 31%;
  z-index: 9;
}

.manage_pricing i#slickNext {
  right: 0;
}

@media only screen and (max-width: 991px) {
  .flr2.slick-carousel {
    display: block !important;
  }
  .col-sm-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .cel1,
  .cel2 {
    width: 100%;
  }
  .mngfeat1,
  .mngfeat2,
  .mngfeat3 {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 414px) {
  .cel1 img,
  .cel2 img {
    left: 42%;
  }
}

@media only screen and (max-width: 375px) {
  .cel1 img,
  .cel2 img {
    left: 41%;
  }
}

@media only screen and (max-width: 320px) {
  .cel1 img,
  .cel2 img {
    left: 39%;
  }
}
</style>
