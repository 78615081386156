<template>
  <div class="unsubscribe">
    <img
      src="@/assets/images/unsubscribe-img.svg"
      alt=""
      class="unsubscribe__img"
    />

    <h3 class="unsubscribe__title">Are you sure you want to unsubscribe?</h3>

    <p class="unsubscribe__text">
      By canceling your subscription, you will lose access to Resume-Perfect’s
      library of templates. Downloading your completed resumes and cover letters
      will also be unavailable. You may still renew your plan at any time.
    </p>

    <div class="unsubscribe-btns">
      <AppButton
        title="Cancel"
        class="unsubscribe-btns__item"
        className="primary"
        @click.prevent="$emit('cancel')"
      />

      <AppButton
        title="Unsubscribe"
        class="unsubscribe-btns__item"
        className="secondary"
        @click.prevent="$emit('unsubscribe')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "UnsubscribeModal",

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.unsubscribe {
  text-align: center;

  &__img {
    width: 100%;
    max-width: 220px;
    display: inline-flex;
    margin-bottom: 52px;
  }

  &__text {
    font-size: 14px;
    color: $light-gray;
    margin-top: 16px;
    margin-bottom: 27px;
  }
}

.unsubscribe-btns {
  display: flex;
  align-items: center;
  width: 100%;

  &__item {
    min-width: initial;
    margin-right: 20px;
    flex: 1;
    font-size: 14px !important;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
